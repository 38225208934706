import React from "react";

import op from "./assets/images/op-v3.png";
const HomeImages3 = (props) => {
  return (
    <div className="col-12 col-md-6 col-lg-7">
      <img src={op} alt="EasyCryptoBot " className="w-100" />
    </div>
  );
};

export default HomeImages3;
